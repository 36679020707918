import { Button } from "@fscrypto/ui";
import clsx from "clsx";
import { HeartIcon } from "lucide-react";
import { tracking } from "~/utils/tracking";

interface LikeFilterProps {
  onSetLikedByMe?: (v: boolean) => void;
  likedByMe: boolean;
}

const LikeFilter = ({ onSetLikedByMe, likedByMe }: LikeFilterProps) => {
  return (
    <Button
      onClick={() => {
        tracking("filter_dashboard_by_liked", "Discover");
        onSetLikedByMe?.(!likedByMe);
      }}
      variant="secondary"
      size="sm"
    >
      <HeartIcon
        className={clsx("size-4", {
          "fill-red-50 text-red-50": likedByMe,
          "text-gray-60 dark:text-gray-30": !likedByMe,
        })}
      />
      <span className="dark:text-gray-30 ml-1 mr-1 text-sm text-gray-100">Liked</span>
    </Button>
  );
};

export default LikeFilter;
