import { Avatar } from "@fscrypto/ui";
import { Link, useLocation, useNavigate } from "@remix-run/react";
import { useDebouncedCallback } from "use-debounce";
import { useCurrentUser } from "~/features/current-user";
import { updateTagStore } from "~/features/tags";
import { DashboardSearchData } from "~/services/discover-service";
import { tracking } from "~/utils/tracking";
import { DashboardRow } from "./components/dashboard-card";
import SortBy from "./components/dashboards-sort-by";
import LikeFilter from "./components/like-filter";
import { NoResults } from "./components/no-results";
import { Paging } from "./components/paging";
import { ProjectFilterList, ProjectFilterSelect } from "./components/project-filter";
import SearchTerm from "./components/search-term";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@fscrypto/flip-kit/table";

interface DashboardsContainerProps {
  data: DashboardSearchData;
  basePath: string;
}

export const DashboardsContainer = ({ data, basePath }: DashboardsContainerProps) => {
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { page, pageSize, liked, project, projects, sortBy, totalResults, search, ownerProfile, items, tags } = data;

  tags.resources.forEach((r) =>
    updateTagStore(
      r.id,
      tags.tags.filter((t) => r.tagIds.includes(t.id)),
    ),
  );

  // Debounce callback
  const handleSearch = useDebouncedCallback(
    (searchTerm: string) => {
      if (basePath.includes("verified-insights")) {
        tracking("search_on_verified_insights_page", "Discover");
      } else {
        tracking("search_on_dashboards_page", "Discover");
      }
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete("page");

      if (searchTerm === "") {
        searchParams.delete("search");
      } else {
        searchParams.set("search", searchTerm);
      }

      const newUrl = `${location.pathname}?${searchParams.toString()}`;

      navigate(newUrl);
    },
    // delay in ms
    800,
  );

  const handleProjectChange = (project: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("page");
    if (project === "all") {
      navigate(`${basePath}?${searchParams.toString()}`);
    } else {
      if (ownerProfile) {
        navigate(`${basePath}/projects/${project}?${searchParams.toString()}`);
      } else {
        navigate(`${basePath}/${project}?${searchParams.toString()}`);
      }
    }
  };

  const handleSort = (sort: string) => {
    const searchParams = new URLSearchParams(location.search);
    if (sort === "") {
      searchParams.delete("sortBy");
    } else {
      searchParams.set("sortBy", sort);
    }

    const newUrl = `${location.pathname}?${searchParams.toString()}`;

    navigate(newUrl);
  };

  const handleLiked = (liked: boolean) => {
    const searchParams = new URLSearchParams(location.search);
    if (liked) {
      searchParams.set("liked", "true");
    } else {
      searchParams.delete("liked");
    }

    const newUrl = `${location.pathname}?${searchParams.toString()}`;

    navigate(newUrl);
  };

  const getPagedUrl = (page: number) => {
    const searchParams = new URLSearchParams(location.search);
    if (page) {
      searchParams.set("page", page.toString());
    } else {
      searchParams.delete("page");
    }

    return `${location.pathname}?${searchParams.toString()}`;
  };

  return (
    <main className="grid grid-cols-1 gap-10 md:grid-cols-4">
      <div className="col-span-3">
        <div className="flex flex-col space-y-3 pb-8 md:flex-row md:space-x-3 md:space-y-0">
          <SearchTerm onChange={handleSearch} value={search} />
          <SortBy
            sortBy={sortBy ?? "trending"}
            onChange={(sort) => {
              const event = determineSortEvent(sort as DashboardSearchData["sortBy"]);
              tracking(event, "Discover");
              handleSort?.(sort);
            }}
          />
          <div className="md:hidden">
            <ProjectFilterSelect onChange={handleProjectChange} projects={projects} projectName={project} />
          </div>
          {currentUser && <LikeFilter likedByMe={liked ?? false} onSetLikedByMe={handleLiked} />}
        </div>

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
          {!basePath.includes("verified-insights") && items.length === 0 && (
            <div className="col-span-4">
              <NoResults />
            </div>
          )}

          <div className="col-span-3">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Dashboard</TableHead>
                  <TableHead className="hidden md:table-cell">Creator</TableHead>
                  <TableHead className="hidden md:table-cell">Published</TableHead>
                  <TableHead className="hidden lg:table-cell">Activity</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {basePath.includes("verified-insights") && BonkRow()}

                {items.map((dashboard) => (
                  <DashboardRow dashboard={dashboard} key={dashboard.id} showUser />
                ))}
              </TableBody>
            </Table>
          </div>

          {/* {data.items.map((dashboard) => (
            <DashboardCard dashboard={dashboard} key={dashboard.id} showUser />
          ))} */}
        </div>

        <Paging getPagedUrl={getPagedUrl} page={page} pageSize={pageSize} totalResults={totalResults} />
      </div>
      <nav className="cols-span-1">
        <ProjectFilterList basePath={basePath} projects={projects} />
      </nav>
    </main>
  );
};

export const BonkRow = () => {
  return (
    <TableRow>
      <TableCell className="py-7">
        <a className="text-xl" href="https://science.flipsidecrypto.xyz/bonk-burns/" target="_blank" rel="noreferrer">
          Bonk Burn Tool
        </a>
      </TableCell>
      <TableCell>
        <Link to={"/teams/flipside/dashboards"} className="text-contrast flex items-center">
          <Avatar src={undefined} size="sm" className="flex-shrink-0" alt="Flipside Team" />
          <span className="mx-3 line-clamp-1 truncate text-sm">Flipside Team</span>
        </Link>
      </TableCell>
      <TableCell>
        <span>May 20, 2024</span>
      </TableCell>
    </TableRow>
  );
};

export const BonkCard = () => {
  return (
    <div className="text-foreground bg-card ring-stroke hover:ring-stroke/20 group relative block overflow-hidden rounded-lg shadow shadow-black/5 ring-1 transition-colors">
      <a href="https://science.flipsidecrypto.xyz/bonk-burns/" target="_blank" rel="noreferrer">
        <img
          className="aspect-[2/1] w-full bg-white object-cover filter transition-all group-hover:shadow dark:brightness-[85%]"
          src="https://res.cloudinary.com/dsr37ut2z/image/upload/f_auto,q_auto:best,w_500/assets/misc/bonk-burn-tool.jpg"
          alt="Bonk Burn Tool"
          title="Bonk Burn Tool"
        />
        <div className="relative flex h-40 w-full flex-col space-y-1 overflow-hidden p-4">
          <h3>
            <span className="text-contrast pointer-events-none line-clamp-2 block font-medium">Bonk Burn Tool</span>
          </h3>
          <p className="text-muted-foreground line-clamp-3 text-xs">
            <span>May 20, 2024</span> - Tracks the burn rate of $BONK from the various tools and community integrations
            within the Solana ecosystem
          </p>
          <div className="flex-1"></div>
          <div className="align-end flex w-full items-center "></div>
        </div>
      </a>
    </div>
  );
};

const determineSortEvent = (sort: DashboardSearchData["sortBy"]) => {
  switch (sort) {
    case "trending":
      return "filter_dashboard_by_trending";
    case "new":
      return "filter_dashboard_by_newest";
    case "greatest":
      return "filter_dashboard_by_all_time";
    default:
      return "filter_dashboard";
  }
};
